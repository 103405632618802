.menu:hover{
    background-color:#63d471;
    background-image: linear-gradient(315deg, #F14A0B 0%, #ED7215 74%);
    
    color: white;
    cursor: pointer;
        
}
#navbar{
    
    position: relative;
    z-index: 9999;
}
 .menu{
     float: right;
     z-index: 99;
     color: black;
        height:100%;
        padding:24px;
         font-size:17px;
        font-weight:bold;
    }

a{
    text-decoration: none;
    color: inherit
}

.before{
    transition: 1s;
    padding: 0px;
    margin: 0px;
    position: fixed;
    background-color: rgba(255, 255,255,1);
     box-shadow: 1px 1px 5px black ;
    z-index: 1;
    width: 100%;
    font-size: 20px;
    color: black
        
        
}

.after{
    transition: 1s;
    padding: 0px;
    margin: 0px;
    position: fixed;
    background-color: rgba(255, 255,255,1);
    box-shadow: 1px 1px 5px ;
    z-index: 1;
    width: 100%;
    font-size: 16px;
    color: white
        
}


.nav-wrapper ul{
    padding: 0px;
    margin: 0;
    display: inline-block;
    right: 0
        
        
}

.nav-wrapper ul li:hover{
      background-image: linear-gradient(315deg, #e2ae01 0%, #c9bd12 74%);
    color:white
}
.nav-wrapper ul li{
    display: inline-block;
    padding: 15px;
    margin: 0px;
    right: 0px;
    
        
}

.brand-logo{
   margin-right: 38%;     
}

.logo_img{
    width: 5%;
    /* padding-left:18px; */
    padding:5px ;
    margin-bottom:-22px;
        
}
.logo_img_small{
    width: 32%;
    margin-bottom:-10px;
    margin-top: -5px;
        
}


.sms_m{
    background: rgb(250, 204, 0);
    background: linear-gradient(90deg, rgba(21,208,193,1) 0%, rgba(236,217,93,1) 0%, rgba(252,212,118,1) 42%);
    }